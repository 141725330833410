@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Source Code Pro", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #222129;
}

p,
h1,
h2,
h3,
h4,
h5,
strong,
li {
  color: white;
  line-height: 1.5;
}

a > strong {
  color: #a4d9c2 !important;
}

a {
  text-decoration: none !important;
}

code {
  font-family: "Source Code Pro", monospace;
}

.ql-editor {
  font-size: medium;
  font-family: "Source Code Pro", monospace;
}

.ql-toolbar .ql-stroke {
  fill: none !important;
  stroke: #fff !important;
}

.ql-toolbar .ql-fill {
  fill: #fff !important;
  stroke: none !important;
}

button:hover .ql-stroke,
.ql-picker-label:hover .ql-stroke {
  fill: none;
  stroke: #a4d9c2 !important;
}

.ql-active .ql-stroke {
  fill: none;
  stroke: #a4d9c2 !important;
}

button:hover .ql-fill,
.ql-picker-label:hover .ql-fill {
  fill: #a4d9c2 !important;
  stroke: none;
}

.ql-active .ql-fill {
  fill: #a4d9c2 !important;
  stroke: none;
}

.MuiOutlinedInput-notchedOutline {
  /* border-color: white !important; */
  /* border-width: 4px !important; */
}
